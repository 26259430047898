<template>
  <div v-if="showModal" class="w-screen h-screen flex justify-center items-center fixed top-0 left-0 z-50 bg-black/[.4]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
interface ComponentProps {
  showModal: boolean
}

const props = defineProps<ComponentProps>()

watch(
  () => props.showModal,
  () => {
    // This code it¡s used to prevent user to be able to scroll the background content
    if (process.client) {
      document.body.style.overflowY = props.showModal ? 'hidden' : 'auto'
    }
  },
  {immediate: true}
)
</script>
